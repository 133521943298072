


























































import {
  computed,
  defineComponent, ref,
} from '@nuxtjs/composition-api';

import { SimpleImage } from '~/components/common/SimpleImage';
import { MODAL_NAMES, SimpleModal } from '~/components/common/SimpleModal';
import { useUiState } from '~/composables';

export default defineComponent({
  name: 'OutOfStockModal',
  components: {
    SimpleModal,
    OutOfStockForm: () => import('./components/Form.vue'),
    SimpleImage,
  },
  setup() {
    const { modal } = useUiState();

    const isCloseTriggered = ref(false);
    const hasResult = ref(false);
    const product = computed(() => modal.value?.props?.product || {});
    const additionalParams = computed(() => modal.value?.props?.additionalParams || {});
    const isVisible = computed(() => modal.value?.identifier === MODAL_NAMES.outOfStock || {});

    const handleClose = () => {
      isCloseTriggered.value = true;

      setTimeout(() => {
        isCloseTriggered.value = false;
      }, 100);
    };

    const handleSuccess = () => {
      hasResult.value = true;
    };

    return {
      isCloseTriggered,
      hasResult,
      product,
      additionalParams,
      isVisible,
      name: MODAL_NAMES.outOfStock,
      handleClose,
      handleSuccess,
    };
  },
});
